/*fonts*/

/*need to add other fonts potentially*/
@font-face {
  font-family: 'Arcane Nine';
  font-style: normal;
  font-weight: 400;
  /*font-display: swap;*/
  src: url('./theme/fonts/Arcane Nine.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Orborn';
  font-style: normal;
  font-weight: 400;
  /*font-display: swap;*/
  src: url('./theme/fonts/Orborn.otf') format('opentype'), url('./theme/fonts/Orborn.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Ailerons';
  font-style: normal;
  font-weight: 400;
  /*font-display: swap;*/
  src: url('./theme/fonts/Ailerons-Regular.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Acier-TextSolid';
  font-style: normal;
  font-weight: 400;
  /*font-display: swap;*/
  src: url('./theme/fonts/Acier-TextSolid.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Modenine';
  font-style: normal;
  font-weight: 400;
  /*font-display: swap;*/
  src: url('./theme/fonts/Modenine.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}





/*react logo stuff?*/
.App {
  text-align: center;
}

/*NodeJs logo animation*/
.node-logo {
  height: 27vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .node-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*react logo animation*/
.App-logo {
  height: 40vmin;
  width: 45vmin;
  pointer-events: none;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*MongoDb logo animation*/
.mongo-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .mongo-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*Chakra logo animation*/
.chakra-logo {
  height: 25vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .chakra-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Not used */
/* .App-header {
  background-color: #6178ac;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*custom classes*/

/*Film Card Classes START*/
.containerdiv {
  float: left;
  position: relative;
  width: 175px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.containerdiv:hover {
  transform: translateY(-5px);
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
}

.filmCardFormat{
  width:175px;
  height:175px;
  background-size:cover;
  background-position:center;
  border: 3px solid white;
  object-fit:cover;
  border-radius:8%;
}
/* mobile version of these 2 classes above */
@media only screen and (max-width: 767px) {
  .containerdiv {
    float: left;
    position: relative;
    width: 150px;  }

  .filmCardFormat {
    width:150px;
    height:150px;
    background-size:cover;
    background-position:center;
    border: 3px solid white;
    object-fit:cover;
    border-radius:8%;  }
}

.QCardFormat{
  width:60px;
  height:60px;
  background-size:cover;
  background-position:center;
  background-color: black;
  border: 3px solid white;
  object-fit:cover;
  border-radius:20%;
  position: absolute;
  top: -20px;
  right: -20px;
}

.QCardNumberFormat {
  font-family: Orborn;
  position: relative;
  font-size: 20px;
  /*height: 20px;*/
  top: -1px;
  /*justify-content: flex-end ;*/
  left: 6px;
}

.QCardIconFormat {
  position: absolute;
  height: 25px;
  bottom: 5px;
  left: 5px;
}

.QCardTitleFormat {
  font-family: Arcane Nine;
  text-align:left;
  font-size: 14px;
  padding-top: 6px;
  max-width: 175px;
  word-wrap: break-word;
}

.QCardYearFormat{
  font-family: Arcane Nine;
  color: gray;
  text-align:left;
  font-size: 10px;
  /*padding-top: 2px;*/
  margin-top: -4px;
}

/*Film Card Classes END*/

/*FEATURE Film Card Classes*/
.featureFilmCardVstack{
  width:250px;
  float: left;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featureFilmCardVstack:hover {
  transform: translateY(-5px);
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
}

.featureFilmCardFormat{
  /*width:225px;*/
  height:250px;
  background-size:cover;
  background-position:center;
  border: 3px solid white;
  object-fit:cover;
  border-radius:8%;
}

@media only screen and (max-width: 767px) {
  .featureFilmCardVstack{
    width:150px;
    float: left;
    position: relative;
  }
  
  .featureFilmCardFormat{
    /*width:225px;*/
    height:150px;
    background-size:cover;
    background-position:center;
    border: 3px solid white;
    object-fit:cover;
    border-radius:8%;
  }
}

.featureQCardFormat{
  width:75px;
  height:75px;
  background-size:cover;
  background-position:center;
  background-color: black;
  border: 3px solid white;
  object-fit:cover;
  border-radius:20%;
  position: absolute;
  top: -20px;
  right: -20px;
}

.featureQCardNumberFormat {
  font-family: Orborn;
  position: relative;
  font-size: 25px;
  /*height: 20px;*/
  top: 0px;
  /*justify-content: flex-end ;*/
  left: 10px;
}

.featureQCardIconFormat {
  position: absolute;
  height: 32px;
  bottom: 8px;
  left: 7px;
}

@media only screen and (max-width: 767px) {
  .featureQCardFormat{
    width:60px;
    height:60px;
    background-size:cover;
    background-position:center;
    background-color: black;
    border: 3px solid white;
    object-fit:cover;
    border-radius:20%;
    position: absolute;
    top: -20px;
    right: -20px;
  }

  .featureQCardNumberFormat {
    font-family: Orborn;
    position: relative;
    font-size: 20px;
    /*height: 20px;*/
    top: -1px;
    /*justify-content: flex-end ;*/
    left: 6px;
  }
  .featureQCardIconFormat {
    position: absolute;
    height: 25px;
    bottom: 5px;
    left: 5px;
  }
}

.featureQCardTitleFormat{
  font-family: Arcane Nine;
  text-align:left;
  font-size: 20px;
  padding-top: 10px;
  /*justify-content: flex-start;*/
  /*position: relative;*/
  /*height: 20px;*/
  /*top: -15px;*/
  /*left: -15px;*/
}
.featureQCardYearFormat{
  font-family: Arcane Nine;
  color: gray;
  text-align:left;
  font-size: 14px;
  margin-top: -6px;
  /*padding-top: 1px;*/
}

@media only screen and (max-width: 767px) {
  .featureQCardTitleFormat {
    font-family: Arcane Nine;
    text-align:left;
    font-size: 14px;
    padding-top: 6px;
    max-width: 175px;
    word-wrap: break-word;
  }

  .featureQCardYearFormat{
    font-family: Arcane Nine;
    color: var(--chakra-colors-gray-400);
    text-align:left;
    font-size: 10px;
    /*padding-top: 2px;*/
    margin-top: -4px;
  }
}
/* Feature Film Card Classes End */

/* Profile Page Classes */

.profilePagePicFormat{
  /* width:120px;
  height:120px; */
  background-size:cover;
  background-position:center;
  /* border: 3px solid white; */
  object-fit:cover;
  border-radius:8%;
}

/* End Profile Page Classes */

/* Profile Modal Classes */

.profilePicFormat{
  width:120px;
  height:120px;
  background-size:cover;
  background-position:center;
  border: 2px solid white;
  object-fit:cover;
  border-radius:8%;
}

.profileFullNameFormat {
  font-family: Arcane Nine;
  text-align: center;
  font-size: 18px;
  padding-top: 6px;
  max-width: 175px;
  word-wrap: break-word;
}

.profileUsernameFormat {
  font-family: Arcane Nine;
  text-align: center;
  font-size: 14px;
  color: gray;
  padding-top: 6px;
  max-width: 175px;
  word-wrap: break-word;
}

@media only screen and (max-width: 767px) {
  .profilePicFormat{
    width:100px;
    height:100px;
    background-size:cover;
    background-position:center;
    border: 2px solid white;
    object-fit:cover;
    border-radius:8%;
  }
}
/* End Profile Modal Classes */

/* Playlist Page Classes */
.playlistTitleFormat {
  font-family: Arcane Nine;
  text-align: center;
  font-size: 32px;
  /* padding-top: 6px; */
  max-width: 175px;
  word-wrap: break-word;
}
/* End Playlist Page Classes */



/* Playlist Modal Classes */

/* End Playlist Modal Classes */



/*classes for font spacing will prob get incorporated in the theme.js file instead*/
.spacing1{
  letter-spacing:4px;
}

.spacing2{
  letter-spacing:8px;
}

/*Profile SEARCH classes START */
.profileCardUsernameFormat {
  font-family: Arcane Nine;
  text-align:left;
  font-size: 14px;
  padding-top: 6px;
  max-width: 175px;
  word-wrap: break-word;
}

.profileCardFullNameFormat{
  font-family: Arcane Nine;
  /* color: var(--chakra-colors-qPurple-400); */
  color: var(--chakra-colors-pink-400);
  /* color: pink.400; */
  /* color: gray; */
  text-align:left;
  font-size: 10px;
}

.profileCardPlaylistCountFormat{
  font-family: Arcane Nine;
  color: var(--chakra-colors-gray-400);
  /* color: gray; */
  /* color: var(--chakra-colors-qPurple-400); */
  text-align:left;
  font-size: 9px;
}
/* Profile SEARCH classes END */


/*Nav Bar Classes START*/
/* The switch - the box around the slider */

/*Alpha Icon Classes START*/
.alphaContainerdiv {
  float: left;
  position: relative;
}

.alphaIconFormat {
  position: absolute;
  height: 20px;
  top: 20px;
  right: 5px;
}
/*Alpha Icon Classes End*/

/*START react Custom Switch Classes*/

.switch-label {
  /*display: block;*/
  margin-top: -10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  /*background: rgb(149, 207, 246);*/
  width: 45px;
  height: 24px;
  /*border-radius: 10px;*/
  position: relative;
  cursor: pointer;
}

.switch-button {
  background-color: white;
  width: 20px;
  height: 20px;
  /*border-radius: 9px;*/
  position: absolute;
  transition: 0.2s;
  left: 2px;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-checkbox {
  width: 0;
  height: 0;
  display: hidden;
}

/*END react custom switch classes*/

#home_button {
  width: 150px;
  height: 150px;
  background-image: url('../public/QLogoWeb.png');
  background-origin: content-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50% 50%;
  /*background-color: #eee;*/
}

#tmdbIcon {
  width: 150px;
  /*height: 150px;*/
  /*background-image: url('../public/tmdbLong.svg');*/
  /*background-origin: content-box;*/
  /*background-size: contain;*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*border-radius: 50% 50%;*/
  /*background-color: #eee;*/
}

/*refactored class from .ejs version */
.search-txt {
  border: none;
  border-bottom: #FFF solid 2px;
  /*border: white;*/
  background: none;
  outline: none;
  /*color: white;*/
  /* font-size: 20px; */
  /*font-size: 2em;*/
  text-align: center;
  justify-content: center;
  width: 50%
}

/*Nav Bar Classes END*/


/*About Page Classes*/
.aboutPageFormat {
  border-radius: 15px;
  background-color: #65C8D0;
  margin: 40px;
}

@media only screen and (max-width: 767px) {
  .aboutPageFormat {
    border-radius: 15px;
    background-color: #65C8D0;
    margin: 20px;
  }
}

.aboutNumberFormat {
  width: 50px;
  height: 50px;
  font-family: Orborn;
  font-size: 30px;
  color:black;
  /*justify-content: center;*/
  /*align-items: center;*/
  /*background-size: cover;*/
  background-position: center;
  /*background-color: black;*/
  border: 3px solid white;
  /*object-fit: cover;*/
  border-radius: 20%;
}

/* might not be even using this class */
@media only screen and (max-width: 767px) {
  .aboutNumberFormat {
    width: 40px;
    height: 40px;
    font-family: Orborn;
    font-size: 20px;
    color:black;
    background-position: center;
    border: 3px solid white;
    border-radius: 20%;
  }
}

.aboutFormulaNumberFormat {
  width: 20px;
  height: 20px;
  font-family: Orborn;
  font-size: 12px;
  color:black;
  background-position: center;
  border: 2px solid white;
  border-radius: 20%;
}



/* when textAlignment Class is applied have to set color to black from a class for it to work somereason */
.textAlignmentLeft {
  text-align: left;
}

.blackText {
  color: black;
}

@media only screen and (max-width: 767px) {
  .blackText {
    color: black;
    font-size: small;
  }
}

.textAlignment {
  /*margin:0 auto;*/
  text-align:center;
  padding-top:10px;
}

.widthControl{
    padding-top:10px;
    text-align:justify;
    width: 90%;

}

.aboutOverline {
  border-top: #916DBE solid 8px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .aboutOverline {
    border-top: #916DBE solid 4px;
    width: 100%;
  }
}

.aboutProfileCardFormat{
  width:250px;
  height:250px;
  background-size:cover;
  background-position:center;
  border: 3px solid white;
  object-fit:cover;
  border-radius:10%;
}

/*about page Formula Format*/

.aboutQCardFormat{
  float: left;
  position: relative;
  width:200px;
  height:200px;
  background-size:cover;
  background-position:center;
  background-color: black;
  border: 5px solid white;
  object-fit:cover;
  border-radius:15%;
}

@media only screen and (max-width: 767px) {
  .aboutQCardFormat{
    float: left;
    position: relative;
    width:110px;
    height:110px;
    background-size:cover;
    background-position:center;
    background-color: black;
    border: 5px solid white;
    object-fit:cover;
    border-radius:15%;
  }
}

.aboutQCardNumberFormat {
  font-family: Orborn;
  position: relative;
  font-size: 55px;
  color: white;
  /*height: 20px;*/
  top: 5px;
  /*justify-content: flex-end ;*/
  left: 40px;
}

@media only screen and (max-width: 767px) {
  .aboutQCardNumberFormat {
    font-family: Orborn;
    position: relative;
    font-size: 32px;
    top: 0px;
    left: 20px;
    color: white;
  }
}

.aboutQCardIconFormat {
  position: absolute;
  height: 100px;
  bottom: 30px;
  left: 15px;
}

@media only screen and (max-width: 767px) {
  .aboutQCardIconFormat {
    position: absolute;
    height: 56px;
    bottom: 10px;
    left: 5px;
  }
}

.aboutFormulaOverline {
  padding-top: 10px;
  border-top: black solid 4px;
  width: 80%;
}

@media only screen and (max-width: 767px) {
  .aboutFormulaOverline {
    padding-top: 10px;
    border-top: black solid 2px;
    width: 100%;
  }
}


/*Formula Classes END*/

/*About Page Classes END*/


/*RateFilm Classes*/
/* Rate Mobile Film Card Classes Start */
.rateMobileFilmCardVstack{
  width:250px;
  float: left;
  position: relative;
}

.rateMobileFilmCardFormat{
  /*width:225px;*/
  height:250px;
  background-size:cover;
  background-position:center;
  border: 3px solid;
  object-fit:cover;
  border-radius:8%;
}

.rateMobileQCardFormat{
  width:100px;
  height:100px;
  background-size:cover;
  background-position:center;
  border: 3px solid;
  object-fit:cover;
  border-radius:20%;
  position: absolute;
  top: -20px;
  right: -20px;
}

.rateMobileQCardNumberFormat {
  font-family: Orborn;
  position: relative;
  font-size: 30px;
  /*height: 20px;*/
  top: 0px;
  /*justify-content: flex-end ;*/
  left: 16px;
}

.rateMobileQCardIconFormat {
  position: absolute;
  height: 48px;
  bottom: 8px;
  left: 7px;
}

.rateMobileQCardTitleFormat{
  font-family: Arcane Nine;
  text-align:left;
  font-size: 18px;
  /* padding-top: 2px; */
  line-height: 1.1;
  /*justify-content: flex-start;*/
  /*position: relative;*/
  /*height: 20px;*/
  /*top: -15px;*/
  /*left: -15px;*/
}
.rateMobileQCardYearFormat{
  font-family: Arcane Nine;
  text-align:left;
  font-size: 12px;
  color: var(--chakra-colors-gray-400);
  /* margin-top: -2px; */
  /*padding-top: 1px;*/
}

.smRateMobileQCardTitleFormat{
  font-family: Arcane Nine;
  text-align:left;
  font-size: 14px;
  padding-top: 6px;
  line-height: 1;
}

.smRateMobileQCardYearFormat{
  font-family: Arcane Nine;
  text-align:left;
  font-size: 12px;
  /* margin-top: -2px; */
  /*padding-top: 1px;*/
}




/*Rate Mobile Film Card Classes END*/
/* My Rate Classes, one at bottom displaying your rating */
.myRateQCardFormat{
  float: left;
  position: relative;
  width:175px;
  height:175px;
  background-size:cover;
  background-position:center;
  /* background-color: black; */
  border: 5px solid #916DBE;
  object-fit:cover;
  border-radius:15%;
}

.myRateQCardNumberFormat {
  font-family: Orborn;
  position: relative;
  font-size: 50px;
  top: -6px;
  left: 35px;
}

.myRateQCardIconFormat {
  position: absolute;
  height: 100px;
  bottom: 12px;
  left: 12px;
}

/*need to update this to work */
.rateImageFormat{
  /*width:100%;*/
  /*height:1080/2px;*/
  background-size:cover;
  background-position:center;
  /*border: 3px solid white;*/
  object-fit:cover;
  /*border-radius:8%;*/
}

/*not sure we are using this class*/
.avgQRateFormat{
  width:120px;
  height:120px;
  background-size:cover;
  background-position:center;
  border: 3px solid white;
  object-fit:cover;
  border-radius:8%;
}

.zIndexRateUpper{
  z-index: -1;
  position: fixed;
  top:-8px;
  left: 0;
  width: 100%;
  padding-top: 0px;
  /*height: 100%;*/
}

.zIndexRateLower{
  z-index: 0;
  position: relative;
  width: 100%;
  top:500px;
  left: 0;
  /*padding-top: 0px;*/
  /*height: 100%;*/
}

.no_wrap_text{
  display:flex;
  flex-wrap: nowrap;   /* will prevent flex items on the same line from wrapping */
  white-space: nowrap; /* will prevent text from wrapping */
}

/* .slider-container {
  display: inline-block;
} */


/* animations START */

/* shimmer text animation quartile */
.shimmer {
  display: inline-block;
  background: linear-gradient(
    to right,
    #916DBE 20%,
    #61dafb 70%,
    #916DBE 100%
  );
  background-size: 200% auto;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 12s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

/* featured films fade in */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}


/* animations END */


